/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
//@import "/node_modules/bootstrap/scss/mixins";

// Import Fuse core library
//@import "@fuse/scss/core";

@use "sass:meta";
@use "variables";
/*@import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css');


// Import app.theme.scss
@import "../app/app.theme";*/

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');


@include meta.load-css('../../node_modules/ngx-sharebuttons/themes/default');
@include meta.load-css('../../node_modules/ngx-sharebuttons/themes/material');
@include meta.load-css('../../node_modules/ngx-sharebuttons/themes/modern');

.border-accent {
    border-color: var(--fuse-accent-500) !important;
}

.dark .mat-step-header {
    background-color: var(--fuse-accent-700) !important;
}

.mdc-notched-outline__notch
{
  border-left: none !important;
}

.myusers-dialog-container .mat-mdc-dialog-surface{
    overflow-y: hidden !important;
}

h2{
    text-transform: uppercase;
    font-family: "Quicksand";
}

.title-font{
    font-family: "Quicksand";
}

.toggle-container
{
    .mat-form-field-wrapper{
        margin: 0 !important;
        padding: 0 !important;
    }
    .mat-form-field-subscript-wrapper{
        margin: 0 !important;
        padding: 0 !important;
    }
}
// Global custom design
.custom-dialog-container .mat-dialog-container {
    padding: 0;
    overflow: hidden;
    position: relative;
}

// ==== NAV BAR ==== //

// ==== HEADER ==== //
.header {
    height: 200px !important;
    min-height: 200px !important;
    max-height: 200px !important;
    background-size: cover;
    z-index: 80 !important;
    position: relative;
    overflow: hidden;

    /*@include media-breakpoint('xs') {
        height: 240px;
        padding: 16px;
    }*/

    .hero-icon {
        position: absolute;
        top: -32px;
        left: 0px;
        opacity: 0.04;
        font-size: 256px !important;
        width: 256px !important;
        height: 256px !important;
        //z-index: -5;
    }

    h1 {
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.01em;
        text-align: center;
        margin-top: 0;
        margin-bottom: 16px;

        /*@include media-breakpoint('xs') {
            font-size: 24px;
        }*/
    }

    h3 {
        position: absolute;
        left: 24px;
        bottom: 8px;

        //max-width: 600px;
        text-align: center;
        font-weight: 300;
        letter-spacing: 0.03em;
        margin: auto;

       /* @include media-breakpoint('xs') {
            font-size: 14px;
        }*/
    }

}

.title-analytics{
    color: var(--fuse-accent-500);
}

// ==== PAGE-CONTENT ==== //
.page-content {

    width: 100%;
    height: 100%;
    padding: 18px;
    background-color: #E6E6E6;

    .card {

        border: none !important;
        border-radius: 5px !important;
        padding: 16px;
        margin-bottom: 18px;
        background-color: white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        .card-title {
            font-size: 1.3em;
            font-weight: bold;
        }

        // Custom card content with id
        // .card-content { }

    }

    .center {
        width: 100%;
        display: flex;
        justify-content: center;
    }

}


/* MAT STEPPER */
.mat-step-header {
    background-color: rgb(236, 236, 236);
}

body.theme-it .mat-step-header.cdk-keyboard-focused, body.theme-it .mat-step-header.cdk-program-focused, body.theme-it .mat-step-header:hover {
	background-color: rgb(236, 236, 236);
}



.redColor {
    .mat-radio-inner-circle{
        background-color: #d50000 !important;

    }
    .mat-radio-outer-circle{
        border-color: #d50000 !important;
    }

    mat-icon {
        color: #d50000 !important;
    }
}
mat-icon.redColor {
    color: #d50000 !important;
}

.pinkColor {
    .mat-radio-inner-circle{
        background-color: #ff1744 !important;
    }
    .mat-radio-outer-circle{
        border-color: #ff1744 !important;
    }

    mat-icon {
        color: #ff1744 !important;

    }
}
mat-icon.pinkColor {
    color: #ff1744 !important;

}

.purpleColor {
    .mat-radio-inner-circle{
        background-color: #d500f9 !important;
    }
    .mat-radio-outer-circle{
        border-color: #d500f9 !important;
    }

    mat-icon {
        color: #d500f9 !important;

    }
}
mat-icon.purpleColor {
    color: #d500f9 !important;

}

.indigoColor {
    .mat-radio-inner-circle{
        background-color: #3d5afe !important;
    }
    .mat-radio-outer-circle{
        border-color: #3d5afe !important;
    }

    mat-icon {
        color: #3d5afe !important;

    }
}
mat-icon.indigoColor {
    color: #3d5afe !important;

}

.cyanColor {
    .mat-radio-inner-circle{
        background-color: #00e5ff !important;
    }
    .mat-radio-outer-circle{
        border-color: #00e5ff !important;
    }

    mat-icon {
        color: #00e5ff !important;

    }
}
mat-icon.cyanColor {
    color: #00e5ff !important;

}

.greenColor {
    .mat-radio-inner-circle{
        background-color: #00c853 !important;
    }
    .mat-radio-outer-circle{
        border-color: #00c853 !important;
    }

    mat-icon {
        color: #00c853 !important;

    }
}
mat-icon.greenColor {
    color: #00c853 !important;

}

.yellowColor {
    .mat-radio-inner-circle{
        background-color: #ffea00 !important;
    }
    .mat-radio-outer-circle{
        border-color: #ffea00 !important;
    }

    mat-icon {
        color: #ffea00 !important;

    }
}
mat-icon.yellowColor {
    color: #ffea00 !important;

}

.orangeColor {
    .mat-radio-inner-circle{
        background-color: #ff6d00 !important;
    }
    mat-ripple-element{
        background-color: #ff6d00 !important;
    }
    .mat-radio-outer-circle{
        border-color: #ff6d00 !important;
    }

    mat-icon {
        color: #ff6d00 !important;

    }
}
mat-icon.orangeColor {
    color: #ff6d00 !important;

}

.brownColor {
    .mat-radio-inner-circle{
        background-color: #6d4c41 !important;
    }
    .mat-radio-outer-circle{
        border-color: #6d4c41 !important;
    }

    mat-icon {
        color: #6d4c41 !important;

    }
}
mat-icon.brownColor {
    color: #6d4c41 !important;

}

.greyColor {
    .mat-radio-inner-circle{
        background-color: #757575 !important;
    }
    .mat-radio-outer-circle{
        border-color: #757575 !important;
    }

    mat-icon {
        color: #757575 !important;

    }
}
mat-icon.greyColor {
    color: #757575 !important;

}

.blackColor {
    .mat-radio-inner-circle{
        background-color: #000000 !important;
    }
    .mat-radio-outer-circle{
        border-color: #000000 !important;
    }

    mat-icon {
        color: #000000 !important;

    }
}
mat-icon.blackColor {
    color: #000000 !important;

}
.whiteColor {
    .mat-radio-inner-circle{
        background-color: #FFF !important;
    }
    .mat-radio-outer-circle{
        border-color: #FFF !important;
    }

    mat-icon {
        color: #FFF !important;

    }
}
mat-icon.whiteColor {
    color: #FFF !important;

}

$space: 28px;

/* HEADER */
.dialog-header {

    margin-bottom: $space;

    h1 {
        margin: 0;
        font-weight: bold;
    }

    h3 {
        margin: 0;
        color: #7B7B7B;
    }
}

.gridforMedia
{
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 5xl:grid-cols-7 6xl:grid-cols-8 gap-y-6 lg:gap-y-6 md:gap-x-6;
}
