@import "variables";

.sb-material-light, .sb-material-dark {
  .sb-wrapper {
    font-family: 'Roboto', sans-serif;

    --sb-border-radius: 2px;
    --sb-transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    --sb-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

    &:active {
      --sb-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    }

    &.sb-show-icon.sb-show-text {
      --sb-padding: 0 0 0 0.3em;
      --sb-text-padding: 0 1em 0 0;
    }
  }
}

.sb-material-dark .sb-wrapper {
  --sb-color: #{$sb-accent};
  --sb-background: var(--button-color);
}

.sb-material-light .sb-wrapper {
  --sb-color: var(--button-color);
  --sb-background: #{$sb-accent};
}
